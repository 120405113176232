import React, { useState, useEffect, useLayoutEffect, memo, ReactNode } from 'react'
import BasicHighlight from './BasicHighlight'
import BannerNotFound from '../../../../static/404.jpg'
import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'
import Content from './style'
import { HighlightProps } from './types'

const WIDTH_MD = 768
let timeout: number

function Highlight ({ children, bannerDesktop, bannerMobile, customStyle, highlightContext }: HighlightProps) {
  const [ animateText, setAnimateText ] = useState(false)
  const [ backgroundImage, setBackgroundImage ] = useState('')
  const domReady = useDomReady()
  const windowWidth = useWidth(300)
  const isDesktop = windowWidth >= WIDTH_MD
  let content: JSX.Element | ReactNode = <></>

  useLayoutEffect(() => {
    if (isDesktop) {
      setBackgroundImage(`url(${bannerDesktop.src ? bannerDesktop.src : BannerNotFound}), url(${bannerDesktop.base64 ? bannerDesktop.base64 : BannerNotFound})`)
    } else {
      setBackgroundImage(`url(${bannerMobile.src ? bannerMobile.src : BannerNotFound}), url(${bannerMobile.base64 ? bannerMobile.base64 : BannerNotFound})`)
    }
  }, [ isDesktop ])

  useEffect(() => {
    timeout = setTimeout(() => setAnimateText(true), 100)
    return () => {
      setAnimateText(false)
      clearTimeout(timeout)
    }
  }, [])

  if (children) {
    content = children
  } else if (highlightContext) {
    content = <BasicHighlight highlightContext={highlightContext} />
  }

  return (
    <Content
      customStyle={customStyle}
      style={{
        backgroundImage: !domReady ? `url(${bannerDesktop.base64})` : backgroundImage,
      }}
    >
      <div className={`container ${animateText ? 'animated' : ''} `}>
        {content}
      </div>
    </Content>
  )
}

export default memo<HighlightProps>(Highlight)
