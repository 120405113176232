import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'
import { Hero } from './sections/Hero/_index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const SaldoDevedor = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <div className='container'>
        <div className='content-body'>
          <div className='content-main mb-5 pt-5'>
            <h3 className='fs-22 fs-md-30'>
              Veja como solicitar o seu saldo devedor do Consignado
            </h3>
            <p>
              Caso precise de informações sobre seu saldo devedor ou sobre seus
              contratos de Crédito Consignado, você poderá ter acesso aos
              seguintes <strong>documentos</strong>:
            </p>
            <ul className='text-gray--600'>
              <li>Documento descritivo do crédito (DDC)</li>
              <li>Boleto de saldo devedor</li>
              <li>
                Número do contrato do seu Empréstimo Consignado ou cópia do
                contrato do seu Empréstimo Consignado{' '}
              </li>
            </ul>
            <p>
              Para isso, basta entrar em contato com o nosso time de atendimento
              por um dos nossos números de telefone:
            </p>
            <ul className='text-gray--600'>
              <li>
                <a
                  href='tel:30034070'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      element_action: 'click button',
                      element_name: ' 3003 4070',
                      section_name:
                        'Veja como solicitar o seu saldo devedor do Consignado',
                      redirect_url: 'tel:30034070',
                    })
                  }}
                >
                  3003 4070
                </a>{' '}
                (capitais e regiões metropolitanas)
              </li>
              <li>
                <a
                  href='tel:08009400007'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      element_action: 'click button',
                      element_name: '0800 940 0007',
                      section_name:
                        'Veja como solicitar o seu saldo devedor do Consignado',
                      redirect_url: 'tel:30034070',
                    })
                  }}
                >
                  0800 940 0007
                </a>{' '}
                (outras localidades) e selecione a opção 5
              </li>
            </ul>
            <p>
              Se você for um cliente correntista do Inter e precisar apenas do
              Documento descritivo do crédito (DDC), você pode retirá-lo pelo
              nosso app. Para isso, basta seguir este caminho: Empréstimo {'>'}{' '}
              Empréstimo Consignado {'>'} Contratos {'>'} Documentos.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SaldoDevedor
