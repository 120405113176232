import React, { useMemo, ComponentClass, FunctionComponent } from 'react'
import Loadable, { LoadableComponent } from 'react-loadable'
import { LoadingIndicator } from 'inter-site-components'
import Link from 'components/GatsbyLinkWrapper'

import { HighlightContextProps, BasicHighlightProps, ISvgIcon } from './types'

const BasicHighlight = ({ highlightContext }: BasicHighlightProps) => {
  const { icon, menuSection, title, subtitle, ctaTitle, ctaLink }: HighlightContextProps = highlightContext

  const SvgIcon: (ComponentClass<ISvgIcon> & LoadableComponent) | (FunctionComponent<ISvgIcon> & LoadableComponent) = useMemo(() => (
    Loadable({
      loader: () => import(`inter-frontend-svgs/lib/Icons/${icon}`),
      loading: () => <LoadingIndicator size='small' />,
    })
  ), [])

  return (
    <div id='highlight' className='row align-items-center'>
      <div className='col-md-9 col-lg-7'>
        { menuSection &&
          <div className='d-flex align-items-center mb-3'>
            <div className={`content-svg icon-${icon}`}>
              <SvgIcon width='39' height='30' color='white' />
            </div>
            <h3 className='text-white fs-sm-18 fs-16 fw-300 ml-2'>{menuSection}</h3>
          </div>
        }
        <h1 className='text-white fs-30 fs-md-50 fw-600'>{title}</h1>
        {subtitle && <p className='text-white'>{subtitle}</p>}
        {ctaLink && <p><Link className='btn btn--green btn--lg btn--landing-main' to={ctaLink}>{ctaTitle}</Link></p>}
      </div>
    </div>
  )
}

export default BasicHighlight
