import styled, { FlattenSimpleInterpolation } from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

type ContentProps = {
  customStyle?: FlattenSimpleInterpolation;
}

export default styled.section<ContentProps>`
  align-items: flex-end;
  background-size: cover, cover, cover;
  background-position: center, center, center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  color: ${white};
  display: flex;
  min-height: 370px;
  position: relative;
  width: 100%;

  &:before {
    content: '';
    background-image: linear-gradient(to right, rgba(140, 47, 12, 0.8) 60%, rgba(140, 47, 12, 0.5));
    height: 100%;
    position: absolute;
    width: 100%;
  }

  > .container {
    padding-bottom: 10px;
    padding-top: 90px;
    transition: all 2000ms ease;

    @media ${device.tablet} {
      padding-left: 10px;
    }
  }

  @media ${device.desktopLG} {
    min-height: 470px;
  }

  .landing-page & {
    background-position: center, center, center;
    background-repeat: no-repeat, no-repeat, no-repeat;
    min-height: 480px;
    overflow: hidden;

    @media ${device.tablet} {
      background-size: cover, cover, cover;
      background-position: center, center, center;
      min-height: 370px;
    }

    @media ${device.desktopLG} {
      align-items: center;
      height: 780px;
      min-height: 780px;
    }
  }

  .content-svg {
    display: flex;
    height: 30px;
    width: 39px;

    > div {
      display: flex;
      height: 30px;
      width: 39px;

      & + h3 {
        margin-bottom: 0;
        margin-left: 12px;
      }
    }

    &.icon-inter-invest-white {
      width: 100px; 
      height: 30px;
      + h3 { 
        display: none !important; 
      }
      > svg {
        width: 100px !important; 
        height: 30px !important;
      }
    }

    & + h3 {
      margin-bottom: 0;
      margin-left: 11px;
    }
  }

  .gatsby-image-outer-wrapper {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  .video-container {
    bottom: 0;
    display: block;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${(props: ContentProps) => props.customStyle}
`
